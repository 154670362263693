// MathematicsPage.js

import React from 'react';
import './Math.css'; // Import the CSS file

const MathematicsPage = () => {
  const mathWebsites = [
    {
      topic: 'General Math',
      resources: [
        {
          name: 'Khan Academy',
          description: 'A free online learning platform offering practice exercises and instructional videos.',
          link: 'https://www.khanacademy.org/',
        },
        {
          name: 'Wolfram Alpha',
          description: 'A computational engine that answers queries and generates visualizations for mathematics.',
          link: 'https://www.wolframalpha.com/',
        },
        {
          name: 'Brilliant',
          description: 'Interactive learning in math and science through fun and challenging problems.',
          link: 'https://brilliant.org/',
        },
      ],
    },
    {
      topic: 'Advanced Mathematics',
      resources: [
        {
          name: 'Art of Problem Solving (AoPS)',
          description: 'Community and resources for high-performing math students.',
          link: 'https://artofproblemsolving.com/',
        },
        {
          name: 'Pauls Online Math Notes',
          description: 'Comprehensive tutorial and practice problems for college-level mathematics.',
          link: 'http://tutorial.math.lamar.edu/',
        },
      ],
    },
    {
      topic: 'Math Videos',
      resources: [
        {
          name: 'YouTube - Numberphile',
          description: 'YouTube channel exploring interesting topics in mathematics.',
          link: 'https://www.youtube.com/user/numberphile',
        },
      ],
    },
    {
      topic: 'Graphing and Calculators',
      resources: [
        {
          name: 'Desmos',
          description: 'An advanced graphing calculator implemented as a web application.',
          link: 'https://www.desmos.com/',
        },
      ],
    },
    {
      topic: 'Math Competitions',
      resources: [
        {
          name: 'International Mathematical Olympiad (IMO)',
          description: 'The most prestigious math competition for high school students worldwide.',
          link: 'https://www.imo-official.org/',
        },
        {
          name: 'American Mathematics Competitions (AMC)',
          description: 'A series of competitions for students in the United States.',
          link: 'https://www.maa.org/math-competitions/amc-american-mathematics-competitions',
        },
      ],
    },
    {
      topic: 'Interactive Math',
      resources: [
        {
          name: 'GeoGebra',
          description: 'Dynamic mathematics software for all levels of education.',
          link: 'https://www.geogebra.org/',
        },
      ],
    },
  ];

  return (
    <div className="math-page-container">
      <h3 className="math-page-header">Mathematics Resources</h3>
      <p className="math-page-description">
        Explore these helpful math websites to enhance your learning experience:
      </p>
      {mathWebsites.map((topic, index) => (
        <div key={index} className="math-topic-container">
          <h4 className="math-topic-header">{topic.topic}</h4>
          <ul className="math-website-list">
            {topic.resources.map((website, subIndex) => (
              <li key={subIndex} className="math-website-item">
                <strong>{website.name}</strong> - {website.description}{' '}
                <a
                  href={website.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="math-website-link"
                >
                  (Visit Website)
                </a>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default MathematicsPage;
