import React from 'react';
import './Math.css';

const Science = () => {
  const scienceResources = {
    physics: [
      {
        name: 'Physics Classroom',
        description: 'Physics Classroom provides interactive lessons covering various physics topics, from motion and forces to energy and waves.',
        link: 'https://www.physicsclassroom.com/',
      },
      {
        name: 'MIT OpenCourseWare - Physics',
        description: 'Explore MIT\'s OpenCourseWare to access free physics courses taught by MIT professors. A great resource for in-depth physics study.',
        link: 'https://ocw.mit.edu/courses/physics/',
      },
    ],
    chemistry: [
      {
        name: 'ChemCollective',
        description: 'ChemCollective offers virtual labs and scenarios to help students explore and understand chemistry concepts in an interactive way.',
        link: 'http://chemcollective.org/',
      },
      {
        name: 'Royal Society of Chemistry',
        description: 'Visit the Royal Society of Chemistry\'s website for a wealth of educational resources, including videos, experiments, and articles.',
        link: 'https://edu.rsc.org/',
      },
    ],
    biology: [
      {
        name: 'Biology Project - University of Arizona',
        description: 'The Biology Project provides online biology tutorials, problem sets, and quizzes covering various biological concepts and topics.',
        link: 'https://www.biology.arizona.edu/',
      },
      {
        name: 'National Geographic - Biology',
        description: 'National Geographic\'s biology section offers articles, videos, and interactive features to explore the wonders of the biological world.',
        link: 'https://www.nationalgeographic.com/science/biology/',
      },
    ],
    geography: [
      {
        name: 'National Geographic - Geography',
        description: 'Explore National Geographic\'s geography resources, including articles, maps, and interactive features that cover a wide range of geographical topics.',
        link: 'https://www.nationalgeographic.com/travel/geography/',
      },
      {
        name: 'Google Earth',
        description: 'Google Earth allows you to explore the world through satellite imagery, maps, and 3D terrain. A powerful tool for studying geography and understanding the Earth.',
        link: 'https://www.google.com/earth/',
      },
    ],
    general: [
      {
        name: 'OpenStax',
        description: 'OpenStax provides free, peer-reviewed, openly licensed textbooks suitable for college courses. They cover various scientific disciplines, including physics, chemistry, and biology.',
        link: 'https://openstax.org/',
      },
      {
        name: 'TED-Ed - Science & Technology',
        description: 'TED-Ed offers a collection of engaging and educational videos on various science and technology topics, suitable for students and educators.',
        link: 'https://ed.ted.com/lessons?category=science-technology',
      },
    ],
  };

  return (
    <div className="math-page-container">
      <h1 className="math-page-header">Science Educational Resources</h1>

      {/* Physics */}
      <div className="math-topic-container">
        <h2 className="math-topic-header">Physics</h2>
        <ul className="math-website-list">
          {scienceResources.physics.map((resource, index) => (
            <li key={index} className="math-website-item">
              <h3>{resource.name}</h3>
              <p>{resource.description}</p>
              <a
                href={resource.link}
                className="math-website-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                (Visit Website)
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* Chemistry */}
      <div className="math-topic-container">
        <h2 className="math-topic-header">Chemistry</h2>
        <ul className="math-website-list">
          {scienceResources.chemistry.map((resource, index) => (
            <li key={index} className="math-website-item">
              <h3>{resource.name}</h3>
              <p>{resource.description}</p>
              <a
                href={resource.link}
                className="math-website-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                (Visit Website)
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* Biology */}
      <div className="math-topic-container">
        <h2 className="math-topic-header">Biology</h2>
        <ul className="math-website-list">
          {scienceResources.biology.map((resource, index) => (
            <li key={index} className="math-website-item">
              <h3>{resource.name}</h3>
              <p>{resource.description}</p>
              <a
                href={resource.link}
                className="math-website-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                (Visit Website)
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* Geography */}
      <div className="math-topic-container">
        <h2 className="math-topic-header">Geography</h2>
        <ul className="math-website-list">
          {scienceResources.geography.map((resource, index) => (
            <li key={index} className="math-website-item">
              <h3>{resource.name}</h3>
              <p>{resource.description}</p>
              <a
                href={resource.link}
                className="math-website-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                (Visit Website)
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* General */}
      <div className="math-topic-container">
        <h2 className="math-topic-header">General</h2>
        <ul className="math-website-list">
          {scienceResources.general.map((resource, index) => (
            <li key={index} className="math-website-item">
              <h3>{resource.name}</h3>
              <p>{resource.description}</p>
              <a
                href={resource.link}
                className="math-website-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                (Visit Website)
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Science;
