import React from 'react';
import './Math.css'; // Import the Math.css file

const ComputerSciencePage = () => {
  return (
    <div className="math-page-container">
      <h3 className="math-page-header">Computer Science Page</h3>
      <p className="math-page-description">
        Welcome to the computer science page! Here, you can find a collection of useful programming resources across various categories.
      </p>

      <div className="math-topic-container">
        <h4 className="math-topic-header">General Programming Resources:</h4>
        <ul className="math-website-list">
          <li className="math-website-item">
            Codecademy - Interactive learning platform covering various programming languages.
            <a
              href="https://www.codecademy.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="math-website-visit-link"
            >
              (Visit Website)
            </a>
          </li>
          <li className="math-website-item">
            freeCodeCamp - Community-driven platform with free coding challenges and projects.
            <a
              href="https://www.freecodecamp.org/"
              target="_blank"
              rel="noopener noreferrer"
              className="math-website-visit-link"
            >
              (Visit Website)
            </a>
          </li>
        </ul>
      </div>

      <div className="math-topic-container">
        <h4 className="math-topic-header">Programming Languages:</h4>
        <ul className="math-website-list">
          <li className="math-website-item">
            JavaScript - A versatile scripting language commonly used for web development.
            <a
              href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
              target="_blank"
              rel="noopener noreferrer"
              className="math-website-visit-link"
            >
              (Visit Website)
            </a>
          </li>
          <li className="math-website-item">
            Python - A powerful and readable language used in various applications, from web development to data science.
            <a
              href="https://docs.python.org/3/"
              target="_blank"
              rel="noopener noreferrer"
              className="math-website-visit-link"
            >
              (Visit Website)
            </a>
          </li>
        </ul>
      </div>

      <div className="math-topic-container">
        <h4 className="math-topic-header">Development Categories:</h4>
        <ul className="math-website-list">
          <li className="math-website-item">
            Frontend Development - Building user interfaces and experiences.
            <ul className="sub-list">
              <li>
                MDN Web Docs - Frontend
                <a
                  href="https://developer.mozilla.org/en-US/docs/Web/Frontend"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="math-website-sub-link"
                >
                  (Visit Website)
                </a>
              </li>
              <li>
                W3Schools
                <a
                  href="https://www.w3schools.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="math-website-sub-link"
                >
                  (Visit Website)
                </a>
              </li>
            </ul>
          </li>
          <li className="math-website-item">
            Backend Development - Managing server-side logic and databases.
            <ul className="sub-list">
              <li>
                Django - Web framework for Python
                <a
                  href="https://www.djangoproject.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="math-website-sub-link"
                >
                  (Visit Website)
                </a>
              </li>
              <li>
                Express.js - Web framework for Node.js
                <a
                  href="https://expressjs.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="math-website-sub-link"
                >
                  (Visit Website)
                </a>
              </li>
            </ul>
          </li>
          <li className="math-website-item">
            Mobile App Development - Creating applications for iOS and Android platforms.
            <ul className="sub-list">
              <li>
                React Native
                <a
                  href="https://reactnative.dev/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="math-website-sub-link"
                >
                  (Visit Website)
                </a>
              </li>
              <li>
                Android Developers
                <a
                  href="https://developer.android.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="math-website-sub-link"
                >
                  (Visit Website)
                </a>
              </li>
              <li>
                Apple Developer
                <a
                  href="https://developer.apple.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="math-website-sub-link"
                >
                  (Visit Website)
                </a>
              </li>
            </ul>
          </li>
          <li className="math-website-item">
            Data Science - Analyzing and interpreting complex data sets.
            <ul className="sub-list">
              <li>
                Kaggle
                <a
                  href="https://www.kaggle.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="math-website-sub-link"
                >
                  (Visit Website)
                </a>
              </li>
              <li>
                DataCamp
                <a
                  href="https://www.datacamp.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="math-website-sub-link"
                >
                  (Visit Website)
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      {/* Repeat the same structure for other sections */}
    </div>
  );
};

export default ComputerSciencePage;
