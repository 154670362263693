import React, { useState } from 'react';
import './App.css';
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import Subjects from './Components/Subjects';

const App = () => {
  const [currentPage, setCurrentPage] = useState('home');

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <Navbar currentPage={currentPage} onPageChange={handlePageChange} />

      <div className="content">
        {currentPage === 'home' && <Home />}
        {currentPage === 'subjects' && <Subjects />}
      </div>
    </div>
  );
};

export default App;
