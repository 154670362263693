// Navbar.js
import React from 'react';
import './Navbar.css';

const Navbar = ({ currentPage, onPageChange }) => {
  return (
    <nav className="navbar">
      <div className="logo" onClick={() => onPageChange('home')}>
      <img src="/screenshot.png" alt="My Screenshot" />
      </div>
      <div className="nav-links">
        <div
          className={`nav-link ${currentPage === 'home' ? 'active' : ''}`}
          onClick={() => onPageChange('home')}
        >
          Home
        </div>
        <div
          className={`nav-link ${currentPage === 'subjects' ? 'active' : ''}`}
          onClick={() => onPageChange('subjects')}
        >
          Subjects
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
