// Subjects.js

import React, { useState } from 'react';
import Mathematics from './Mathematics';
import ComputerScience from './ComputerScience';
import Science from './Science';
import './Subjects.css'; // Import the CSS file

const Subjects = () => {
  const [selectedSubject, setSelectedSubject] = useState('');

  const subjectsList = [
    'Mathematics',
    'Science',
    'Computer Science',
  ];

  const handleSubjectChange = (event) => {
    setSelectedSubject(event.target.value);
  };

  const renderSubjectPage = () => {
    switch (selectedSubject) {
      case 'Mathematics':
        return <Mathematics />;
      case 'Computer Science':
        return <ComputerScience />;
      case 'Science':
        return <Science />;
      default:
        return null;
    }
  };

  return (
    <div className="subjects-container">
      <h2 className="subjects-h2">Subjects</h2>
      <p className="subjects-p">
        Please select your preferred subject:
      </p>

      <div>
        <label htmlFor="subjectDropdown" className="subjects-label">
        </label>
        <select
          id="subjectDropdown"
          value={selectedSubject}
          onChange={handleSubjectChange}
          className="subjects-select"
        >
          <option value="" disabled className="subjects-option">
            Select a subject
          </option>
          {subjectsList.map((subject) => (
            <option key={subject} value={subject} className="subjects-option">
              {subject}
            </option>
          ))}
        </select>
      </div>

      {selectedSubject && (
        <div className="subjects-selected-subject">
          {renderSubjectPage()}
        </div>
      )}
    </div>
  );
};

export default Subjects;
