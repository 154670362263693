// Home.js
import React, { useState } from 'react';
import './Home.css';

const Home = () => {
  const [showContactPopup, setShowContactPopup] = useState(false);

  const handleContactClick = () => {
    setShowContactPopup(true);
  };

  const handleClosePopup = () => {
    setShowContactPopup(false);
  };

  return (
    <div className="home-container">
      <div className="text-container">
        <h1>Welcome to EduCompiler</h1>
        <section className="about-us">
          <p>
            At EduCompiler, we believe that education is a fundamental right that should
            be accessible to everyone, regardless of their geographical location, educational
            availability, or financial background. Our mission is to break down barriers and make
            quality education easily accessible to learners worldwide.
          </p>
          <p>
            In a world where educational opportunities are not uniformly distributed, EduCompiler serves
            as a beacon of inclusivity. We are committed to empowering individuals from all walks of life with the
            knowledge and skills they need to unlock their full potential.
          </p>
        </section>
        <section className="contact">
          <h2>If you have any suggestions or requests, please do not hesitate to contact us.</h2>
          <button onClick={handleContactClick}>Contact</button>
          {showContactPopup && (
            <div className="contact-popup">
              <p>Email us at: contact@educompiler.com</p>
              <button onClick={handleClosePopup}>Close</button>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default Home;
